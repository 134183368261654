import { shouldPolyfill as shouldPolyfillGetCanonicalLocales } from '@formatjs/intl-getcanonicallocales/should-polyfill'
import { shouldPolyfill as shouldPolyfillLocale } from '@formatjs/intl-locale/should-polyfill'
import { shouldPolyfill as shouldPolyfillPluralRules } from '@formatjs/intl-pluralrules/should-polyfill'
import { shouldPolyfill as shouldPolyfillRelativeTimeFormat } from '@formatjs/intl-relativetimeformat/should-polyfill'

// https://formatjs.io/docs/polyfills/intl-relativetimeformat#dynamic-import--capability-detection
export async function polyfillIntl(locale: string): Promise<void> {
  // Only support english for now, we can add more case statements when
  // supporting multiple languages
  if (shouldPolyfillGetCanonicalLocales()) {
    await import('@formatjs/intl-getcanonicallocales/polyfill')
  }

  if (shouldPolyfillLocale()) {
    await import('@formatjs/intl-locale/polyfill')
  }

  if (shouldPolyfillPluralRules(locale)) {
    await import('@formatjs/intl-pluralrules/polyfill')
    await import('@formatjs/intl-pluralrules/locale-data/en')
  }

  if (shouldPolyfillRelativeTimeFormat(locale)) {
    await import('@formatjs/intl-relativetimeformat/polyfill')
    await import('@formatjs/intl-relativetimeformat/locale-data/en')
  }
  return
}
