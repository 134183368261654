// Taken from MUI example
// https://github.com/mui/material-ui/blob/fe3dc188ef28d46e4e68c934963a568797454202/examples/nextjs-with-typescript/src/createEmotionCache.ts
import createCache from '@emotion/cache'

const isBrowser = typeof document !== 'undefined'

export default function createEmotionCache() {
  let insertionPoint

  if (isBrowser) {
    const emotionInsertionPoint = document.querySelector<HTMLMetaElement>(
      'meta[name="emotion-insertion-point"]'
    )
    insertionPoint = emotionInsertionPoint ?? undefined
  }

  return createCache({ key: 'mui-style', insertionPoint })
}
