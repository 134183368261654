const ignoredWarnings = [
  'Material-UI: Either `children`, `image`, `src` or `component` prop must be specified.'
]

// eslint-disable-next-line
console.error = (message, obj1, obj2) => {
  if (ignoredWarnings.includes(obj2)) {
    return false
  }
}

export {}
