import React from 'react'
import { Box, Button, Typography } from '@mui/material'
import { logger } from 'utils/logger'

type State = {
  hasError: boolean
}

type Props = {
  children?: React.ReactNode
}

export default class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(): State {
    return { hasError: true }
  }

  componentDidCatch(error: any) {
    logger.error(error)
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      return (
        <Box
          position="fixed"
          top={0}
          left={0}
          right={0}
          height="100vh"
          bgcolor="black"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Typography color="white" mb={2}>
            An unexpected error occured
          </Typography>
          <Button variant="contained" onClick={() => window.location.reload()}>
            Reload
          </Button>
        </Box>
      )
    }

    return this.props.children
  }
}
