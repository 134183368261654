import * as Sentry from '@sentry/nextjs'
import { CaptureContext } from '@sentry/types'
import merge from 'lodash/merge'

class Logger {
  private userProperties: Record<string, string> = {}

  error(error: any, captureContext?: CaptureContext) {
    const graphQLErrors =
      error?.graphQLErrors && JSON.stringify(error.graphQLErrors)

    // eslint-disable-next-line
    console.error(error)

    Sentry.captureException(
      error,
      merge(
        { extra: { ...this.userProperties, graphQLErrors } },
        captureContext
      )
    )
  }

  setUserProperties(properties: Record<string, string>) {
    this.userProperties = properties
  }
}

export const logger = new Logger()
