// Polyfill IntersectionObsever on iOS < 12.2
if (process.env.NODE_ENV !== 'test') {
  require('intersection-observer')
}
import type { AppProps } from 'next/app'
import type { NextComponentType } from 'next'
import Head from 'next/head'
import { IntlProvider } from 'react-intl'
import 'styles/global.css'
import 'config/amplify'
import 'utils/ignoredErrors'
import ErrorBoundary from 'components/ErrorBoundary'
import { withLDProvider } from 'launchdarkly-react-client-sdk'
import { polyfillIntl } from 'utils/polyfill'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { CacheProvider, EmotionCache } from '@emotion/react'
import createEmotionCache from 'styles/createEmotionCache'

polyfillIntl('en')

type PageComponent = NextComponentType & {
  getLayout: (page: JSX.Element) => JSX.Element
}

interface Props extends AppProps {
  Component: PageComponent
  err: any
  emotionCache?: EmotionCache
}

const Noop = (page: JSX.Element) => page

const clientSideEmotionCache = createEmotionCache()

export function AppProviders({
  children
}: {
  children: JSX.Element
}): JSX.Element {
  return (
    <IntlProvider locale="en">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        {children}
      </LocalizationProvider>
    </IntlProvider>
  )
}

function App({
  Component,
  pageProps,
  err,
  emotionCache = clientSideEmotionCache
}: Props): JSX.Element {
  const getLayout = Component.getLayout || Noop

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <title>Beluga</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <ErrorBoundary>
        {getLayout(<Component {...pageProps} err={err} />)}
      </ErrorBoundary>
    </CacheProvider>
  )
}

export default withLDProvider({
  clientSideID: process.env.NEXT_PUBLIC_LAUNCH_DARKLY_CLIENT_SIDE_ID!,
  user: {
    anonymous: true
  },
  options: {
    bootstrap: 'localStorage'
  }
})(App as any)
